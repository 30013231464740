<template>

<v-container class="home_container page_container">
  
<v-img class="home_hero" :src="hero_img"></v-img>

<services :services="services"/>

<h2 class="page_title">About Us</h2>
<div class="content" v-html="content"></div>
<projects project_block_title="Past Projects" post_limit="3"/>
</v-container>

</template>

<script>
import projects from "@/components/projects.vue"
import services from "@/components/services.vue"

export default {
  components: {
    projects,
    services
  },

data(){
  return{
    hero_img: "",
  content: null,
  services: []
  }
},
mounted() {
  //eslint-disable-next-line
  axios 
    .get('https://wordpress-310083-949429.cloudwaysapps.com/wp-json/wp/v2/pages?slug=home&_embed')
    .then( response =>(
    (this.hero_img = response.data[0]._embedded['wp:featuredmedia'][0].source_url),
    (this.content = response.data[0].content.rendered),
    (this.services = response.data[0].acf.services)
  ))
}

};
</script>
<style>
.home_hero{
  max-height: 50vh;
}
</style>
