<template>
<v-container class="services_container">
<div class="services_container_inner">
    <h2 class="page_title">Services</h2>
    <v-container
    grid-list-xl
    fluid
   >
   <v-layout wrap>
       <v-flex
        v-for="service in services"
    :key="service"
    xs12
    md4
    class="service mont"
       >
       <v-card flat tile>
           <span class="service">{{service.service}}</span>
       </v-card>
       </v-flex>
   </v-layout>
    </v-container>
</div>
</v-container>
</template>
<style>
.service{
    text-align: center;
    font-size: 1.5rem;
}
@media screen and (max-width:960px){
    .service{
        font-size: 1rem;
    }
}
</style>
<script>
export default {
    name: "services",
   props:[
       'services'
   ]
}
</script>
