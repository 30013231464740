<template>
  <div id="app">
    <nav_menu />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <foot />
  </div>
</template>
<script>
import nav_menu from "@/components/menu.vue";
import foot from "@/components/foot.vue";

export default {
  components: {
    nav_menu,
    foot
  }
};
</script>
<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap');

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #222;
}

::selection {
  color: white;
  background: #222;
}

#app {
  min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  text-transform: capitalize;
}
.raleway {
  font-family: "Raleway", sans-serif;
}
p, .mont, li{
  font-family: "Montserrat", sans-serif;
}
html {
  transition-timing-function: ease-in-out;
}
h1{
  font-size: 3rem;
}
.page_container{
  min-height: 100vh;
  width: 80vw;
  padding-bottom: 100px;
}
.page_title{
  margin: 20px 0px;
}
p{
  margin-bottom: 20px;
}
.post_title, .blog_title, .page_title{
  font-size: 2rem;
}
@media screen and (max-width: 960px) {
  .page_title{
    text-align: center;
  }
}
</style>
