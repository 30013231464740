<template>
  <v-footer class="footer mont" dark>

<v-container grid-list-md class="full_foot foot_outter">
<v-layout wrap class="full_foot foot_inner">

<v-flex xs12 md6 class="foot_right">
<router-link to="/contact" class="foot_top">Get In Touch</router-link>
<div class="foot_links">

<a href="tel:+61413235160" itemprop="telephone">0413 235 160</a>
<a href="mailto:stephenlawes@aapt.net.au?Subject=Website%20Enquiry" itemprop="email">stephenlawes@aapt.net.au</a>
<a href="https://www.facebook.com/Adorn-Drafting-Launceston-632763737211524/" itemprop="facebook">Facebook</a>
</div>
</v-flex>
<v-flex xs12 md6 class="foot_left">
    <div class="foot_name foot_top">
        <span class="name">
            Adorn Drafting
        </span>
        <span class="sub_name">
            Architectural Design and Drafting
        </span>
    </div>
    <div class="copyright">
        <span>&copy; {{new Date().getFullYear()}} Adorn Drafting</span>
        <span>Website by <a href="https://topdogcommerce.com" target="_blank" title="Top Dog Commerce">Top Dog Commerce</a></span>
        <span>Hosted with <a href="https://vrlps.co/FAkhk7O/cp" target="_blank">Cloudways</a></span>
    </div>
</v-flex>
</v-layout>
</v-container>
  </v-footer>
</template>
<script>
export default {
  name: "foot"
};
</script>
<style>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  background-color: black;
  font-size: 1rem;
  color: white;
  height: 250px;
}
.footer a{
    color: white;
    text-decoration: none;
}
 .foot_inner{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
.foot_left, .foot_right{
    display: flex;
    flex-direction: column;
    height: 80%;
}
.copyright, .foot_links{
    display: flex;
    flex-direction: column;
}
@media screen and (min-width:960px){  
.full_foot{
    height: 140px;
}
.foot_inner{
    flex-direction: row-reverse;
}
.foot_left, .foot_right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.foot_left{
    align-items: flex-start;
    align-content: flex-start;
}
.foot_right{
    align-items: flex-end;
    align-content: flex-end;
    text-align: right;
}
}
@media screen and (max-width: 960px){
    .footer{
        height: 400px;
    }
    .foot_outter{
        height: 100%;
    }
    .foot_left, .foot_right{
    align-items: center;
    align-content: center;
    justify-content: space-evenly; 
    text-align: center;  
    height: 180px; 
    }
}
</style>
