<template>
  <v-container class="blog_container">
    <div class="blog_container_inner">
      <h2 class="blog_title">{{project_block_title}}</h2>

      <v-container
        grid-list-md
        text-xs-center
        v-for="(post, index) in posts"
        :key="post"
        class="post_row"
      >
        <router-link :to="`/projects/${post.slug}`" class="post_list_link" :class="{'even_post': index %2, 'odd_post': !index %2 }" >
          <v-layout row wrap class="post_list_item">
            <v-flex class="post_list_img" xs12 md8>
              <v-img :src="post._embedded['wp:featuredmedia'][0].source_url" />
            </v-flex>
            <v-flex class="post_list_text" xs12 md4>
                <div class="post_list_text_inner">
 <h2>{{ post.title.rendered }}</h2>
              <p v-html="post.excerpt.rendered"></p>
              <v-btn class="post_list_button" ripple>View Project</v-btn>
                </div>
            </v-flex>
          </v-layout>
        </router-link>
      </v-container>
    </div>
  </v-container>
</template>
<style>
.blog_container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px;
}
.blog_container_inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.post_row{
    padding: 0px!important;
}
.blog_list_item > .list_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.post_list_link {
  text-decoration: none;
  color: black;
}
.post_list_link:hover {
  color: #13294b;
}
.post_list_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  padding: 50px;
}
.post_list_text_inner{
    padding: 50px;
}
.post_list_text_inner p{
    padding: 20px 0px;
}
button.post_list_button {
  border: 1px solid #13294b;
  border-radius: 0px;
  background-color: white!important;
  transition-duration: 0.2s!important;
  width: 150px;
  align-self: center;
}
button.post_list_button.v-btn::before{
    background-color: white;
}
button.post_list_button:hover {
  background-color: black !important;
  transition-duration: 0.2s!important;
  color: white;
}
@media screen and (min-width: 960px){
    .even_post .post_list_item{
        flex-direction: row-reverse;
    }
    .post_list_item{
        padding: 20px 0px;
    }
}
</style>
<script>
export default {
  name: "about",
  props:[
      'project_block_title',
      'post_limit'
  ],
  data() {
    return {
      posts: []
    };
  },
  mounted() {
      let post_lim = this.post_limit;
    axios
      .get(
        "https://wordpress-310083-949429.cloudwaysapps.com/wp-json/wp/v2/posts?_embed&orderby=date&order=asc&per_page="+post_lim
      )
      .then(response => (this.posts = response.data));
  }
};
</script>
