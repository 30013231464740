<template>
  <v-layout wrap style="" app_nav class="full_nav">
    <v-container class="nav_container">
      <v-layout justify-space-between class="nav_inner" align-center>
        <span @click.stop="drawer = !drawer" class="menu_btn">
          <p class="menu_btn_text montserrat">Menu</p>
          <div class="bar_container">
            <span class="bar top_bar"></span>
            <span class="bar mid_bar"></span>
            <span class="bar bot_bar"></span>
          </div>
        </span>
        <router-link to="/" class="title_container">
    <h1 class="site_title">Adorn Drafting</h1>
  <p class="subtitle">Architectural Design and Drafting</p>
  </router-link>
  <router-link to="/contact" class="head_cta">Contact</router-link>
      </v-layout>
    </v-container>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="height:100vh;"
    >
      <v-list class="pa-1">
        <v-list-item class="menu_item">
      <v-list-item-content>
        <v-list-item-title @click.stop="drawer = !drawer" class="menu_btn close_btn">Close</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
      </v-list>

      <v-list class="pt-0 menu_list" dense>
        <v-divider></v-divider>
        <v-list-item class="menu_item" v-for="item in items" :key="item.title">
      <v-list-item-content>
        <v-list-item-title><router-link :to="item.link">{{item.title}}</router-link></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
      </v-list>
      



      <v-divider></v-divider>
      <v-list class="menu_contact">

<v-list-item class="menu_item">
      <v-list-item-content>
        <v-list-item-title><a href="tel:+61413235160" itemprop="telephone">0413 235 160</a></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
<v-list-item class="menu_item">
      <v-list-item-content>
        <v-list-item-title><a href="mailto:stephenlawes@aapt.net.au?Subject=Website%20Enquiry" itemprop="email">stephenlawes@aapt.net.au</a></v-list-item-title>
      </v-list-item-content>
    </v-list-item>

<v-list-item class="menu_item">
      <v-list-item-content>
        <v-list-item-title><a target="_blank" href="https://www.facebook.com/Adorn-Drafting-Launceston-632763737211524/" itemprop="facebook">Facebook</a></v-list-item-title>
      </v-list-item-content>
    </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        { title: "Home", link: "/" },
        { title: "About", link: "/about" },
        { title: "Projects", link: "/projects" },
        { title: "Contact", link: "/contact"}
      ]
    };
  },
  name: "nav_menu"
};
</script>
<style>
.full_nav {
  z-index: 10;
  display: block;
  position: sticky;
  top: 0px;
  width: 100%;
  background-color: white;
  height: 150px;
}
.v-overlay.v-overlay--absolute {
  height: 100vh;
}
.container.nav_container {
  max-width: 100vw;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.nav_inner {
  height: 100%;
  align-content: center;
  align-items:center;
  justify-content: space-between;
}
.menu_btn {
  width: 200px;
  text-align: center;
  font-size: 1.3rem;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.menu_btn_text {
  margin-bottom: 0;
  padding-left: 20px;
  color: transparent;
  transition-duration: 0.2s;
}
.menu_btn:hover .menu_btn_text {
  color: #11294b;
  transition-duration: 0.2s;
  padding-left: 35px;
}
.bar_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-left: 50px;
}
.bar {
  margin: 3px 0px;
  display: block;
  content: "";
  background: #11294b;
  height: 3px;
  width: 30px;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  box-shadow: #ccc 0px 0px 3px;
}
.menu_btn:hover .mid_bar {
  width: 20px;
  transition-duration: 0.2s;
}
.menu_btn:hover .bot_bar {
  width: 10px;
  transition-duration: 0.2s;
}

.title_container{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: black;
  transition-duration: .2s;
  width: auto;
  justify-self: center;
  align-self: center;
}
.title_container:hover, .head_cta:hover{
  opacity: .5;
  transition-duration: .2s;
}

.subtitle{
  margin-top: 10px;
}
.head_cta{
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
    font-family: "Raleway";
    width: 200px;
    text-align: left;
    transition-duration: .2s;
}
.close_btn {
  font-size: 1rem;
  text-align: center;
}
.menu_list{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}
.menu_item, .menu_item.v-list-item {
  padding: 15px 0px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.menu_item a, .close_btn{
    text-decoration: none;
    color: black;
    transition-duration: .2s;
    font-size: 1rem;
}
.menu_item a:hover, .close_btn:hover{
    opacity: .5;
    transition-duration: .2s;
}
@media screen and (max-width: 960px){
    .full_nav{
        height: 150px;
    }
    p.menu_btn_text{
        display: none;
    }
    h1.site_title{
        font-size: 1.2rem;
    }
    p.subtitle{
        display: none;
    }
    .head_cta{
        font-size: 1rem;
    }
}
</style>
